<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1>Overdue retentions report</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table>
          <thead>
            <th class="text-left">Customer</th>
            <th class="text-left">Invoice Number</th>
            <th class="text-left">Retention Amount</th>
            <th class="text-left">Retention payment due date</th>
          </thead>
          <tbody>
            <tr v-for="(retention, index) in retentions" :key="`r_${index}`">
              <td>
                <router-link :to="`/customers/view/${retention.invoice.customer.id}`">
                  {{ retention.invoice.customer.name }}
                </router-link>
              </td>
              <td>
                <router-link :to="`/invoices/view-draft/${retention.invoice.id}`">
                  {{ retention.invoice.quotation.clone_id }}-{{ retention.invoice.quotation.clone_count }}-{{ retention.invoice.quotation.quoted_by_initials }}
                </router-link>
              </td>
              <td>{{ retention.retention_amount | priceInPounds | currency }}</td>
              <td>{{ retention.payment_due_date | tinyDate }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'SalesCommission',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      retentions: [],
    };
  },
  methods: {
    getRetentions() {
      axios.get(`/invoiceRetentions/getRetentions.json?token=${this.token}`)
      .then((response) => {
        this.retentions = response.data.retentions;
      });
    },
  },
  mounted() {
    this.getRetentions();
  },
};
</script>
  